/* Import General */
import { useState } from "react";
import { useStyles } from "./style";

/* Import Material-ui */
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/* Import Material-ui Icons */
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";

/* Import Custom */
import Compressor from 'compressorjs';
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import uploadToS3 from "../../../utils/aws/s3";

function NewExpensesForm(props) {
  const classes = useStyles();

  const { fetchDataCallBack, user } = props;

  const [expensesError, setExpensesError] = useState("");
  const [loading, setLoading] = useState(false);
  const [expensesModalOpen, setExpensesModalOpen] = useState(false);
  const [image, setImage] = useState({
    files: null,
    url: "",
  });
  const [expenses, setExpenses] = useState({
    amount: 0,
    category: "",
    remarks: "",
  });
  const handleChange = (event) => {
    setExpenses({
      ...expenses,
      [event.target.name]: event.target.value,
    });
  };
  const handleNewExpensesClose = () => {
    setExpensesModalOpen(false);
  };

  const handleExpensesImageChange = (e) => {
    const {
      // target: { value, files }
      target: { files },
    } = e;

    /* Compress Image */
    new Compressor(files[0], {
      quality: 0.2,
      success: (compressedResult) => {
        setImage({
          files: compressedResult,
          url: URL.createObjectURL(files[0]),
        });
      },
    });

    setExpensesModalOpen(true);
  };
  const newExpenses = async (event) => {
    event.preventDefault();
    setExpensesError("");
    setLoading(true);
    if (expenses.amount === 0 || expenses.category === "") {
      /* Check if all mandatory fields are completed */
      setExpensesError("Please fill in Amount & Category.");
      setLoading(false);
      return;
    } else {
      /* Upload expenses image to S3 */
      const key = await uploadToS3(image.files);
      if (key !== false) {
        /* Successfully uploaded to S3 */
        const expensesRef = app.firestore().collection("expenses").doc();
        const res = await expensesRef.set({
          amount: parseFloat(expenses.amount),
          category: expenses.category,
          remarks: expenses.remarks,
          date: Date.now(),
          image: key,
          uploader: app.firestore().doc("users/" + user.uid),
        });
        console.log("DEBUGGER: Successfully added new expense - ", res);
        setExpenses({
          amount: 0,
          category: "",
          remarks: "",
        });
        setImage({
          files: [],
          url: "",
        });
        setLoading(false);
        fetchDataCallBack();
        setExpensesModalOpen(false);
        return res;
      } else {
        /* Failed uploaded to S3 */
        setLoading(false);
        fetchDataCallBack();
      }
    }
  };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="expenses-upload"
        type="file"
        capture="environment"
        onChange={handleExpensesImageChange}
      />
      <label htmlFor="expenses-upload">
        <IconButton
          aria-label="Add"
          className={classes.fab}
          color="primary"
          component="span"
        >
          <AddIcon />
        </IconButton>
      </label>
      {/* New Expenses Pop-up Modal */}
      <Modal
        open={expensesModalOpen}
        onClose={handleNewExpensesClose}
        aria-labelledby="new-client-modal"
        aria-describedby="new-client-modal"
      >
        <Paper className={classes.modal}>
          <form onSubmit={newExpenses}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid className={classes.modalHead} item xs={12}>
                <h2>New Expenses</h2>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.imgContainer}>
                  <img src={image.url} alt="receipt"></img>
                </div>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Amount
                  </InputLabel>
                  <OutlinedInput
                    required
                    name="amount"
                    value={expenses.amount}
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    labelWidth={60}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Category</InputLabel>
                  <Select
                    labelId="category"
                    name="category"
                    value={expenses.category}
                    onChange={handleChange}
                    label="Category"
                  >
                    <MenuItem value="meal-entertainment">
                      Meals & Entertainment Expenses
                    </MenuItem>
                    <MenuItem value="travel">Travel Expenses</MenuItem>
                    <MenuItem value="utilities">
                      Rent, Utilities & Phone
                    </MenuItem>
                    <MenuItem value="opex">Operating Expenses</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="remarks"
                  value={expenses.remarks}
                  label="Remarks"
                  onChange={handleChange}
                />
              </Grid>
              {expensesError === "" ? (
                false
              ) : (
                <Grid item xs={12}>
                  <p className={classes.error}>{expensesError}</p>
                </Grid>
              )}
              <Grid item xs={12}>
                {loading ? (
                  <Button className={classes.btnLoading} disabled>
                    <CircularProgress />{" "}
                  </Button>
                ) : (
                  <Button
                    className={classes.btnMain}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}

export default NewExpensesForm;
