/* Import General */
import { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { useStyles } from "./style";

/* Import Material UI */
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

/* Import Icon */
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import StoreIcon from "@material-ui/icons/Store";
import FaceIcon from "@material-ui/icons/Face";
import CakeIcon from "@material-ui/icons/Cake";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

/* Import Custom */
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

function Header(props) {
  const classes = useStyles();
  const { user } = props;

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  const handleDrawerOpen = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  };
  const handleDrawerClose = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 800
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    console.log(user);
  }, [user]);

  // const toHome = () => {
  //   navigate(`/`);
  // };
  const toSignin = () => {
    navigate(`/login`);
  };
  const toQuotation = () => {
    navigate(`/quotation`);
  };
  const toExpenses = () => {
    navigate(`/expenses`);
  };
  const signOut = () => {
    app.auth().signOut();
  };

  const toInstagram = () => {
    window.open("https://www.instagram.com/louislocw/", "_blank");
  };

  const toWhatsapp = () => {
    window.open("whatsapp://send?phone=85264126186", "_blank");
  };

  return (
    <div className={classes.headerSection}>
      <div>
        {/* <img src={Omakasnack_Logo} alt="Omakasnack Logo" onClick={toHome} /> */}
        OROH NAH
      </div>
      {mobileView ? (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <div>
          {user ? (
            <ul>
              <li onClick={toQuotation}>Overview</li>
              <li onClick={toQuotation}>Clients</li>
              <li onClick={toQuotation}>Quotation</li>
              <li onClick={toQuotation}>Invoice</li>
              <li onClick={toExpenses}>Expenses</li>
              <li onClick={signOut}>Logout</li>
            </ul>
          ) : (
            <ul>
              <li onClick={toSignin}>Login</li>
            </ul>
          )}
        </div>
      )}

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <div onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
          {user ? (
            <List className={classes.drawerList}>
              <ListItem button onClick={toQuotation}>
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Overview" />
              </ListItem>
              <ListItem button onClick={toQuotation}>
                <ListItemIcon>
                  <CakeIcon />
                </ListItemIcon>
                <ListItemText primary="Clients" />
              </ListItem>
              <ListItem button onClick={toQuotation}>
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary="Quotation" />
              </ListItem>
              <ListItem button onClick={toQuotation}>
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary="Invoice" />
              </ListItem>
              <ListItem button onClick={toExpenses}>
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary="Expenses" />
              </ListItem>
              <ListItem button onClick={signOut}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          ) : (
            <List className={classes.drawerList}>
              <ListItem button onClick={toSignin}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            </List>
          )}

          <div className={classes.drawerBottom}>
            <Divider />
            <List className={classes.drawerList}>
              <ListItem button onClick={toInstagram}>
                <ListItemIcon>
                  <InstagramIcon />
                </ListItemIcon>
                <ListItemText primary="@louislocw" />
              </ListItem>
              <ListItem button onClick={toWhatsapp}>
                <ListItemIcon>
                  <WhatsAppIcon />
                </ListItemIcon>
                <ListItemText primary="+852 6412 6186" />
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
