/* Import General */
import { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { useStyles } from "./style";

/* Import Material-ui */
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Collapse from "@material-ui/core/Collapse";

/* Import Material-ui Icons */
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* Import Custom */
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

function NewQuotationForm(props) {
  const classes = useStyles();

  const { fetchDataCallBack } = props;

  const [error, setError] = useState("");
  const [clientError, setClientError] = useState("");
  const [clients, setClients] = useState([]);
  const [jobCats, setJobCats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientModalOpen, setClientModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [quotation, setQuotation] = useState({
    id: "",
    client: "",
    category: "",
    amount: 0,
    description: "",
    remarks: "",
    version: 1,
    signed: false,
    completed: false,
    taxable: false,
  });
  const [client, setClient] = useState({
    name: "",
    contactName: "",
    contactPhone: "",
    remarks: "",
  });
  const handleChange = (event) => {
    setQuotation({
      ...quotation,
      [event.target.name]: event.target.value,
    });
  };
  const handleSwitchChange = (event) => {
    setQuotation({
      ...quotation,
      [event.target.name]: event.target.checked,
    });
  };
  const handleClientChange = (event) => {
    setClient({
      ...client,
      [event.target.name]: event.target.value,
    });
  };
  const handleNewClientClick = () => {
    setExpanded(false);
    setClientModalOpen(true);
  };
  const handleNewClientClose = () => {
    setClientModalOpen(false);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /**
   * Pad 0 to the code ID
   * @param {number} n code ID.
   * @param {number} width code length.
   * @param {string} z padding string.
   * @return {string} reg code string.
   */
  function pad(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  /**
   * Find firestore doc which match docID
   * @param {any} array firestore doc array.
   * @param {string} docID doc ID.
   * @return {any} firestore doc.
   */
  function findFirestore(array, docID) {
    let doc = array.find((o) => o.id === docID);
    return doc.data();
  }

  const newQuotation = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    if (
      quotation.client === "" ||
      quotation.category === "" ||
      quotation.amount === 0
    ) {
      /* Check if all mandatory fields are completed */
      setError("Please fill in Client, Category & Amount.");
      setLoading(false);
      return;
    } else {
      /* Add new quotation */
      const quotationRef = app.firestore().collection("quotation").doc();
      const quotationIDRef = app
        .firestore()
        .collection("count")
        .doc("quotation");
      try {
        const res = await app.firestore().runTransaction(async (t) => {
          const quotationIDDoc = await t.get(quotationIDRef);
          const quotationID = quotationIDDoc.data().id + 1;

          const clientID = findFirestore(clients, quotation.client).id;
          const jobCategoryID = findFirestore(jobCats, quotation.category)
            .prefix;

          const quotationIDString =
            quotation.amount >= 900000
              ? "QUO9" + jobCategoryID + clientID + pad(quotationID, 3)
              : "QUO" +
                Math.ceil((quotation.amount / 100000) % 10).toString() +
                jobCategoryID +
                clientID +
                pad(quotationID, 3);

          // Add new quotation
          await t.set(quotationRef, {
            id: quotationIDString,
            client: app.firestore().doc("client/" + quotation.client),
            category: app.firestore().doc("jobCategory/" + quotation.category),
            amount: Number(quotation.amount),
            description: quotation.description,
            remarks: quotation.remarks,
            version: quotation.version,
            signed: quotation.signed,
            completed: quotation.completed,
            taxable: quotation.taxable,
            date: Date.now(),
          });

          // Update new quotation id
          await t.update(quotationIDRef, { id: quotationID });

          return quotationID;
        });
        console.log("DEBUGGER: Successfully added new quotation - ", res);
        setQuotation({
          id: "",
          client: "",
          category: "",
          amount: 0,
          description: "",
          remarks: "",
          version: 1,
          signed: false,
          completed: false,
          taxable: false,
        });
        setLoading(false);
        fetchDataCallBack();
        return res;
      } catch (e) {
        console.log("DEBUGGER: Failed to create new quotation: ", e);
        setError("Unexpected Error, Try again.");
        setLoading(false);
      }
      return;
    }
  };

  const newClient = async (event) => {
    event.preventDefault();
    setClientError("");
    setLoading(true);
    if (client.name === "") {
      /* After setting "required", should never arrive this line */
      setClientError("Client Name is required");
      setLoading(false);
      return;
    } else {
      /* Create client */
      const clientRef = app.firestore().collection("client").doc();
      const clientIDRef = app.firestore().collection("count").doc("client");
      try {
        const res = await app.firestore().runTransaction(async (t) => {
          const clientIDDoc = await t.get(clientIDRef);
          const newClientID = clientIDDoc.data().id + 1;

          // Add new client
          await t.set(clientRef, {
            id: pad(newClientID, 3),
            name: client.name,
            contactName: client.contactName,
            contactPhone: client.contactPhone,
            remarks: client.remarks,
          });

          // Update new registration code
          await t.update(clientIDRef, { id: newClientID });

          return newClientID;
        });
        console.log("DEBUGGER: Successfully added new client - ", res);
        setClient({
          name: "",
          contactName: "",
          contactPhone: "",
          remarks: "",
        });
        setLoading(false);
        return res;
      } catch (e) {
        console.log("DEBUGGER: Failed to create new client: ", e);
        setClientError("Unexpected Error, Try again.");
        setLoading(false);
      }
      return;
    }
  };

  /* Fetch Clients */
  const fetchClient = useCallback(async () => {
    const clientRef = app.firestore().collection("client");

    const snapshot = await clientRef.get();

    if (snapshot.empty) {
      // No Client Found
      console.log(`DEBUGGER: No Client Record Found`);
      setClients([]);
      return false;
    } else {
      // Clients Record => snapshot.docs
      setClients(snapshot.docs);
      return snapshot.docs.length;
    }
  }, []);

  /* Fetch Job Category */
  const fetchJobCat = useCallback(async () => {
    const jobCatRef = app.firestore().collection("jobCategory");

    const snapshot = await jobCatRef.get();

    if (snapshot.empty) {
      // No Job Category Found
      console.log(`DEBUGGER: No Job Category Found`);
      setJobCats([]);
      return false;
    } else {
      // Job Category Record => doc.data()
      setJobCats(snapshot.docs);
      return snapshot.docs.length;
    }
  }, []);

  /* On Render Fetch Data */
  useEffect(() => {
    fetchClient();
    fetchJobCat();
  }, [fetchClient, fetchJobCat]);

  return (
    <Paper className={classes.paper}>
      {clients.length === 0 ? (
        <div>
          <Button variant="outlined" onClick={handleNewClientClick}>
            Create Client
          </Button>
        </div>
      ) : (
        <form onSubmit={newQuotation}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <h2>New Quotation</h2>
            </Grid>

            {/* Form Required */}
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Client</InputLabel>
                <Select
                  labelId="client"
                  name="client"
                  value={quotation.client}
                  onChange={handleChange}
                  label="Client"
                >
                  {clients.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.data().name} ({client.data().id})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Category</InputLabel>
                <Select
                  labelId="category"
                  name="category"
                  value={quotation.category}
                  onChange={handleChange}
                  label="Category"
                >
                  {jobCats.map((jobCat) => (
                    <MenuItem key={jobCat.id} value={jobCat.id}>
                      {jobCat.data().name} ({jobCat.data().prefix})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount
                </InputLabel>
                <OutlinedInput
                  required
                  name="amount"
                  type="number"
                  value={quotation.amount}
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  labelWidth={60}
                />
              </FormControl>
            </Grid>
            {/* Form Optional */}
            <Grid item xs={5}>
              <TextField
                fullWidth
                name="description"
                value={quotation.description}
                label="Description"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                name="remarks"
                value={quotation.remarks}
                label="Remarks"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                name="version"
                type="number"
                value={quotation.version}
                label="Version"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={quotation.signed}
                      onChange={handleSwitchChange}
                      name="signed"
                      color="primary"
                    />
                  }
                  label="Signed"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={quotation.completed}
                      onChange={handleSwitchChange}
                      name="completed"
                      color="primary"
                    />
                  }
                  label="Completed"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={quotation.taxable}
                      onChange={handleSwitchChange}
                      name="taxable"
                      color="primary"
                    />
                  }
                  label="Taxable"
                />
              </FormGroup>
            </Grid>
            {error === "" ? (
              false
            ) : (
              <Grid item xs={12}>
                <p className={classes.error}>{error}</p>
              </Grid>
            )}
            <Grid item xs={12}>
              {loading ? (
                <Button className={classes.btnLoading} disabled>
                  <CircularProgress />{" "}
                </Button>
              ) : (
                <Button
                  className={classes.btnMain}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      )}
      {/* New Client Pop-up Modal */}
      <Modal
        open={clientModalOpen}
        onClose={handleNewClientClose}
        aria-labelledby="new-client-modal"
        aria-describedby="new-client-modal"
      >
        <Paper className={classes.modal}>
          <form onSubmit={newClient}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid className={classes.modalHead} item xs={12}>
                <h2>New Client</h2>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  className={classes.formField}
                  variant="outlined"
                  name="name"
                  value={client.name}
                  label="Name"
                  onChange={handleClientChange}
                />
              </Grid>
              {/* Client Form Advanced Option */}
              <Grid
                item
                className={clsx({
                  [classes.paddingNone]: !expanded,
                })}
                xs={12}
              >
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="contactName"
                        value={client.contactName}
                        label="Contact Name"
                        onChange={handleClientChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="contactPhone"
                        value={client.contactPhone}
                        label="Contact Phone"
                        onChange={handleClientChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="remarks"
                        value={client.remarks}
                        label="Remarks"
                        onChange={handleClientChange}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              {clientError === "" ? (
                false
              ) : (
                <Grid item xs={12}>
                  <p className={classes.error}>{clientError}</p>
                </Grid>
              )}
              <Grid item xs={12}>
                {loading ? (
                  <Button className={classes.btnLoading} disabled>
                    <CircularProgress />{" "}
                  </Button>
                ) : (
                  <Button
                    className={classes.btnMain}
                    variant="contained"
                    type="submit"
                  >
                    Create
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Modal>
    </Paper>
  );
}

export default NewQuotationForm;
