/* Import Material-ui */
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  section: {
    background: "#fff",
    padding: "30px 64px",
    "@media only screen and (max-width: 800px)": {
      padding: "20px 30px",
    },
    "@media only screen and (max-width: 479px)": {
      padding: "20px 20px",
    },
  },
  container: {
    flex: "1 1 auto",
    "& > div": {
      margin: "20px 0",
      // padding: "20px 30px",
    }
  },
  paper: {
    color: "#000",
    "& > form > p, > form > div": {
      width: "100%",
      margin: "10px 0 20px 0",
    },
    "& > button": {
      width: "100%",
      height: "40px",
      margin: "20px 0 10px 0",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
}));
