/* Import General */
import { useState, useEffect, useCallback } from "react";
import { useStyles } from "./style";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";

/* Import API */
// import { AuthCheck, useUser, useAuth } from "reactfire";
import { useSigninCheck, useUser } from "reactfire";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

/* Import Material-ui */
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import MaterialTooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from '@material-ui/core/CircularProgress';

/* Import Recharts */
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  ResponsiveContainer,
} from "recharts";

/* Import Custom */
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import SignIn from "../../auth/signIn";
import NewExpensesForm from "./newExpensesForm";
import { deleteFromS3 } from "../../utils/aws/s3";

/* Table Section */
function descendingComparator(a, b, orderBy) {
  if (b.data()[orderBy] < a.data()[orderBy]) {
    return -1;
  }
  if (b.data()[orderBy] > a.data()[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "$",
  },
  { id: "remarks", numeric: true, disablePadding: false, label: "Remarks" },
  { id: "date", numeric: true, disablePadding: false, label: "Date" },
];

function EnhancedTableHead(props) {
  const {
    // classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, expense } = props;

  const deleteExpense = async (event) => {
    event.preventDefault();

    selected.forEach(async (expenseID) => {
      const matchItem = expense.find((item) => item.id === expenseID);

      await deleteFromS3(matchItem.data().image);
      await app.firestore().collection("expenses").doc(expenseID).delete();
    });
    // fetchData();
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Expenses
        </Typography>
      )}

      {numSelected > 0 ? (
        <MaterialTooltip title="Delete">
          <IconButton onClick={deleteExpense} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </MaterialTooltip>
      ) : (
        <MaterialTooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </MaterialTooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  expense: PropTypes.array.isRequired,
};

function Expenses() {
  const classes = useStyles();

  const [expense, setExpense] = useState([]);

  /* Construct Chart Data */
  const [lineChartData, setLineChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  /* Table Elements */
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = expense.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, expense.length - page * rowsPerPage);

  /* Get User Data -> user.uid | Must be wrapped inside "AuthCheck" */
  const { data: user } = useUser();
  const { status, data: signInCheckResult } = useSigninCheck(); 

  /* Transform Line Chart Data */
  const transformLineChartData = (docs) => {
    let returnData = [];
    let currentKey = "";

    docs.forEach((doc) => {
      var key = moment(doc.data().date).format("MMM YY");
      if (key === currentKey) {
        if (doc.data().remarks === "Lpy") {
          returnData[returnData.length - 1]["Lpy"] =
            returnData[returnData.length - 1]["Lpy"] + doc.data().amount;
        } else {
          returnData[returnData.length - 1]["Others"] =
            returnData[returnData.length - 1]["Others"] + doc.data().amount;
        }
      } else {
        returnData.push({ name: key, Lpy: 0, Others: 0 });
        currentKey = key;
        if (doc.data().remarks === "Lpy") {
          returnData[returnData.length - 1]["Lpy"] =
            returnData[returnData.length - 1]["Lpy"] + doc.data().amount;
        } else {
          returnData[returnData.length - 1]["Others"] =
            returnData[returnData.length - 1]["Others"] + doc.data().amount;
        }
      }
    });

    return returnData;
  };

  /* Transform Bar Chart Data */
  const transformBarChartData = (docs) => {
    let returnData = [];

    docs.forEach((doc) => {
      console.log(doc.data().category);

      const foundIndex = returnData.findIndex(item => item.name === doc.data().category)

      if (foundIndex === -1) {
        returnData.push({ name: doc.data().category, value: doc.data().amount });
      } else {
        returnData[foundIndex].value = returnData[foundIndex].value + doc.data().amount;
      }
    });

    return returnData;
  };

  /* Fetch Expense Data */
  const fetchData = useCallback(async () => {
    const expenseRef = app
      .firestore()
      .collection("expenses")
      .orderBy("date", "asc");

    const snapshot = await expenseRef.get();

    if (snapshot.empty) {
      // No Expense Record Found
      console.log(`DEBUGGER: No Expense Record Found`);
      return false;
    } else {
      // Expense Records => snapshot.docs
      setExpense(snapshot.docs);
      setLineChartData(transformLineChartData(snapshot.docs));
      setBarChartData(transformBarChartData(snapshot.docs));
      return snapshot.docs.length;
    }
  }, []);

  // const signOut = () => {
  //   app.auth().signOut();
  // };

  /* On Render Fetch Data */
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {status === "loading" ? (
        <div className="main-loading">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {signInCheckResult.signedIn === true ? (
            <div className="App">
              <Header user={user} />
              <div className={classes.section}>
                <div className={classes.container}>
                  {/* Line Chart */}
                  <Paper className={classes.chartpaper}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={lineChartData}
                        margin={{
                          top: 20,
                          right: 50,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="Lpy"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                        <Line type="monotone" dataKey="Others" stroke="#82ca9d" />
                      </LineChart>
                    </ResponsiveContainer>
                  </Paper>
    
                  {/* Pie Chart */}
                  <Paper className={classes.chartpaper}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={barChartData}
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                          label
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Paper>
    
                  {/* New Expenses */}
                  <NewExpensesForm fetchDataCallBack={fetchData} user={user} />
                  {/* Expense List */}
                  <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                      numSelected={selected.length}
                      selected={selected}
                      expense={expense}
                    />
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead
                          classes={classes}
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={expense.length}
                        />
                        <TableBody>
                          {stableSort(expense, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((doc, index) => {
                              const isItemSelected = isSelected(doc.id);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              const imgURL = `https://devlocw-expenses.s3.ap-east-1.amazonaws.com/${
                                doc.data().image
                              }`;
    
                              return (
                                <TableRow
                                  hover
                                  onClick={(event) => handleClick(event, doc.id)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={doc.id}
                                  selected={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      inputProps={{ "aria-labelledby": labelId }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    <img src={imgURL} alt={doc.data().image} />
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                  >
                                    {doc.data().category}
                                  </TableCell>
                                  <TableCell align="right">
                                    {doc.data().amount}
                                  </TableCell>
                                  <TableCell align="right">
                                    {doc.data().remarks}
                                  </TableCell>
                                  <TableCell align="right">
                                    {moment(doc.data().date).format(
                                      "Do MMM YY, HH:mm"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={7} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={expense.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Paper>
                </div>
              </div>
              <Footer />
            </div>
          ) : (
            <SignIn />
          )}
        </div>
      )}
    </div>
  );
}

export default Expenses;
