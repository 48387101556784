/* Import Material-ui */
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& h1, h2": {
      fontSize: "3.3333rem",
      lineHeight: 1.2,
    },
    "& h3": {
      fontSize: "1.25rem",
      margin: "20px 0",
      lineHeight: 1.2,
    },
    "& p": {
      fontSize: "1.25rem",
      lineHeight: 1.5,
    },
    "& .longDesc": {
      fontSize: "1rem",
    },
    "& span.highlight": {
      color: theme.palette.primary.main,
    },
    "& span.highlightAlt": {
      color: theme.palette.secondary.main,
    },
  },
  bannerImgHalf: {
    clipPath: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
  },
  bannerSection: {
    background: "#fff",
    padding: "96px 64px",
    textAlign: "left",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "30vh",
    borderBottom: "1px solid",
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    "& p": {
      color: theme.palette.grey[600],
    },
    "@media only screen and (max-width: 800px)": {
      padding: "40px 30px",
    },
    "@media only screen and (max-width: 479px)": {
      padding: "30px 20px",
    },
  },
  section: {
    background: "#fff",
    padding: "96px 64px",
    textAlign: "left",
    "& p": {
      color: theme.palette.grey[600],
    },
    "@media only screen and (max-width: 800px)": {
      padding: "40px 30px",
    },
    "@media only screen and (max-width: 479px)": {
      padding: "30px 20px",
    },
  },
  coloredSection: {
    background: "rgb(247, 249, 250) !important",
  },
  container: {
    flex: "1 1 auto",
    "& > div": {
      margin: "20px 0",
      // padding: "20px 30px",
    }
  },
  paper: {
    textAlign: "center",
    color: "#000",
    padding: "20px 20px",
    "& button": {
      width: "100%",
      height: "40px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  center: {
    textAlign: "center",
  },
  imgSection: {
    textAlign: "center",
    "& img": {
      maxHeight: "400px",
      maxWidth: "100%",
      width: "auto",
    }
  },
  iconChip: {
    width: "70px",
    height: "70px",
    borderRadius: "16px",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "& svg": {
      fontSize: "2.5rem",
    },
  },
}));
