/* Import General */
import { v4 as uuid } from "uuid";
import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const uploadToS3 = async (file) => {

  const extension = file.name.split(".")[1];
  // const { type: mimeType } = file;
  const key = `${uuid()}.${extension}`;

  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: key,
  };

  var putObjectPromise = myBucket.putObject(params).promise();

  const response = await putObjectPromise.then((data) => {
    console.log("DEBUGGER: Successfully uploaded to s3", data);
    return key;
  }).catch((err) => {
    console.log("DEBUGGER: Failed to upload s3", err);
    return false;
  });

  return response;

};

const deleteFromS3 = async (name) => {

  console.log("Triggered delete: ", name);

  const params = {
    Bucket: S3_BUCKET,
    Key: name,
  };

  var deleteObjectPromise = myBucket.deleteObject(params).promise();

  const response = await deleteObjectPromise.then((data) => {
    console.log("DEBUGGER: Successfully deleted object from s3 ", data);
    return true;
  }).catch((err) => {
    console.log("DEBUGGER: Failed to delete object from s3", err);
    return false;
  });

  return response;

};

export default uploadToS3;
export { deleteFromS3 };
