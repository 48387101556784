/* Import General */
import { useState } from "react";
import { useStyles } from "./style";
import { navigate } from "@reach/router";

/* Import Material-ui */
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

/* Import Material-ui Icons */
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";

/* Import Custom */
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

function Home() {
  const classes = useStyles();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [credential, setCredential] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const handleChange = (event) => {
    setCredential({
      ...credential,
      [event.target.name]: event.target.value,
    });
  };
  const handleClickShowPassword = () => {
    setCredential({
      ...credential,
      showPassword: !credential.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signIn = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    app
      .auth()
      .signInWithEmailAndPassword(credential.email, credential.password)
      .then((userCredential) => {
        // var user = userCredential.user;
        // console.log(user);
        setLoading(false);
        navigate(`/`);
      })
      .catch((error) => {
        var errorCode = error.code;
        // var errorMessage = error.message;
        switch (errorCode) {
          case "auth/user-not-found":
            setError("User Not Found");
            break;
          case "auth/invalid-email":
            setError("Invalid Email");
            break;
          case "auth/wrong-password":
            setError("Wrong Password");
            break;
          default:
            setError("Error, please try again");
        }
        setLoading(false);
        // console.log(errorCode);
        // console.log(errorMessage);
      });
  };

  const signUp = () => {
    console.log("signUp");
  };

  return (
    <div className={classes.section}>
      <div className={classes.authContainer}>
        <Paper className={classes.paper} elevation={0}>
          <h1>Devlocw sign in</h1>
          <form onSubmit={signIn}>
            <TextField
              name="email"
              value={credential.email}
              label="Username / Email"
              onChange={handleChange}
            />

            <FormControl>
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={credential.showPassword ? "text" : "password"}
                name="password"
                value={credential.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {credential.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <p className={classes.error}>{error}</p>

            {loading ? (
              <Button className={classes.btnLoading} disabled>
                <CircularProgress />{" "}
              </Button>
            ) : (
              <Button
                className={classes.btnMain}
                color="primary"
                variant="contained"
                type="submit"
              >
                Sign in
              </Button>
            )}
          </form>
        </Paper>
        <Paper className={classes.paper} elevation={0}>
          <h2>Don't have an Account?</h2>
          <Button variant="outlined" onClick={signUp} disabled>
            Create Account (disabled)
          </Button>
        </Paper>
      </div>
    </div>
  );
}

export default Home;
