/* Import Material-ui */
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "450px",
    minWidth: "450px",
    textAlign: "left",
    color: "#000",
    padding: "20px 20px",
    "& button": {
      width: "100%",
      height: "40px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    "@media only screen and (max-width: 800px)": {
      minWidth: "70%",
    },
    "@media only screen and (max-width: 479px)": {
      minWidth: "70%",
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    width: "unset !important",
    height: "unset !important",
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    width: "unset !important",
    height: "unset !important",
  },
  paper: {
    textAlign: "center",
    color: "#000",
    padding: "20px 20px",
    "& button": {
      width: "100%",
      height: "40px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  modalHead: {
    display: "flex",
  },
  btnMain: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "700",
    height: "40px",
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  btnLoading: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "700",
    height: "40px",
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.primary.main,
    },
    "& > span > div": {
      height: "25px !important",
      width: "25px !important",
      color: theme.palette.primary.contrastText,
    },
  },
  btnLoadingOutlined: {
    height: "40px",
    "& > span > div": {
      height: "25px !important",
      width: "25px !important",
    },
  },
  error: {
    color: "#aa0000",
    margin: "0 !important",
    fontSize: "0.7em",
  },
  paddingNone: {
    padding: "0 !important",
  },
  spacing: {
    padding: "8px",
  },
  imgContainer: {
    height: "150px",
    maxHeight: "150px",
    background: "whitesmoke",
    textAlign: "center",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  fab: {
    position: "fixed",
    zIndex: "999",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
}));
