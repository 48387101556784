/* Import Custom */
import { useStyles } from "./style";

/* Import Icon */
import IconButton from "@material-ui/core/IconButton";
// import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

function Footer() {
  const classes = useStyles();

  // const toInstagram = () => {
  //   window.open("https://www.instagram.com/omakasnack/", "_blank");
  // };

  const toWhatsapp = () => {
    window.open("whatsapp://send?phone=85264126186", "_blank");
  };

  return (
    <div className={classes.footerSection}>
      <div>
        <p>© Devlocw</p>
      </div>
      <ul>
        {/* <li>
          <IconButton onClick={toInstagram} edge="start" color="inherit" aria-label="menu">
            <InstagramIcon />
          </IconButton>
        </li> */}
        <li>
          <IconButton onClick={toWhatsapp} edge="start" color="inherit" aria-label="menu">
            <WhatsAppIcon />
          </IconButton>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
