/* Import General */
import { useState, useEffect } from "react";
import { useStyles } from "./style";

/* Import API */
// import { AuthCheck, useUser, useAuth } from "reactfire";
import { useSigninCheck, useUser } from "reactfire";

/* Import Material-ui */
import Grid from "@material-ui/core/Grid";

/* Import Material-ui Icons */
import CodeIcon from "@material-ui/icons/Code";
import MoodIcon from "@material-ui/icons/Mood";
import VideocamIcon from "@material-ui/icons/Videocam";
import CircularProgress from '@material-ui/core/CircularProgress';

/* Import image */
import children from "../../../images/children.png";
import manWithHat from "../../../images/man_with_hat.png";

/* Import Custom */
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ContactButton from "../contactButton";

function Home() {
  const classes = useStyles();

  /* Get User Data -> user.uid | Must be wrapped inside "AuthCheck" */
  const { data: user } = useUser();
  const { status } = useSigninCheck();

  const [state, setState] = useState({
    mobileView: false,
  });
  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <div>
      {status === "loading" ? (
        <div className="main-loading">
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.root}>
          <Header user={user}/>
          <div className={classes.bannerSection}>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <h1>
                  Digital solutions for your{" "}
                  <span className="highlightAlt">Business</span>
                </h1>
                <p>Create your own digital solution with us.</p>
                <ContactButton label={"GET STARTED"} />
              </Grid>
              {mobileView ? (
                false
              ) : (
                <Grid item xs={12} sm={6}>
                  <div className={classes.imgSection}>
                    <img src={manWithHat} alt="banner" />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          <div className="site-width">
            <div className={classes.section}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} className={classes.center}>
                  <h2>
                    Start your business <br />
                    <span className="highlight">with creativity</span>
                  </h2>
                  <p>
                    Websites, mobile apps, marketing, KOL, SEM, SEO, production
                    house...
                    <br />
                    Anything you need to start your business.
                  </p>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <div className={classes.iconChip}>
                        <CodeIcon />
                      </div>
                      <h3>Build anything</h3>
                      <p className="longDesc">
                        Build accessible web and mobile apps for your business.
                        Flexible, fully customizable & modern design.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className={classes.iconChip}>
                        <MoodIcon />
                      </div>
                      <h3>Digital Marketing</h3>
                      <p className="longDesc">
                        SEM & SEO solutions, content marketing, influencers and KOL
                        marketing. Get connected to public.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className={classes.iconChip}>
                        <VideocamIcon />
                      </div>
                      <h3>Productions</h3>
                      <p className="longDesc">
                        Tell a story with short videos. Get rid of standard & boring
                        stuff, make creative films that tell a different story.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.coloredSection}>
            <div className="site-width">
              <div className={`${classes.section} ${classes.coloredSection}`}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <h2>Less panic. More value</h2>
                    <p>
                      OROH NAH is a one-stop digital marketing house for your
                      business. Start building, marketing & tell your story with us
                      now.
                    </p>
                    <ContactButton label={"GET STARTED"} />
                  </Grid>
                  {mobileView ? (
                    false
                  ) : (
                    <Grid item xs={12} sm={6} className={classes.imgSection}>
                      <img src={children} alt="contact us" />
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>

    
  );
}

export default Home;
