/* Import Material-ui */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    borderTop: "1px solid #000",
    padding: "10px 64px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& ul li": {
      listStyle: "none",
      display: "inline",
      margin: "0px 10px 0px 0px",
    },
    "& ul": {
      margin: "5px 0px",
    },
    "& li": {
      cursor: "pointer",
      padding: "10px 10px",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    "& button": {
      padding: "10px 10px",
    },
    "& img": {
      height: "65px",
    },
    "@media only screen and (max-width: 800px)": {
      padding: "10px 30px",
    },
    "@media only screen and (max-width: 479px)": {
      padding: "10px 20px",
    },
  },
}));

export { useStyles };
