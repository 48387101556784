/* Import General */
import { useState } from "react";
import { useStyles } from "./style";

/* Import Material-ui */
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";


/* Import Material-ui Icons */
import CircularProgress from "@material-ui/core/CircularProgress";

/* Import Custom */
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

function ContactButton(props) {
  const classes = useStyles();

  const { label } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (event) => {
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  };
  const handleContactUs = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const submitContact = async (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    if (contact.email === "") {
      /* Check if all mandatory fields are completed */
      setError("Please at least fill in email");
      setLoading(false);
      return;
    } else {
      /* Submit Contact Form */
      try {
        const res = await app.firestore().collection("contactSubmission").add({
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          message: contact.message,
        });

        console.log("DEBUGGER: Successfully submitted contact form - ", res.id);
        setContact({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        setLoading(false);
        handleModalClose();

        return res;
      } catch (e) {
        console.log("DEBUGGER: Failed submit contact form: ", e);
        setError("Unexpected Error, Try again.");
        setLoading(false);
      }
      return;
    }
  };

  return (
    <div>
      <Button
        className={classes.btnMain}
        color="primary"
        variant="contained"
        onClick={handleContactUs}
      >
        {label}
      </Button>

      {/* New Client Pop-up Modal */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="new-client-modal"
        aria-describedby="new-client-modal"
      >
        <Paper className={classes.modal}>
          <form onSubmit={submitContact}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid className={classes.modalHead} item xs={12}>
                <h2>Contact Us</h2>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  className={classes.formField}
                  variant="outlined"
                  name="name"
                  value={contact.name}
                  label="Name"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  className={classes.formField}
                  variant="outlined"
                  name="email"
                  value={contact.email}
                  label="Email"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  className={classes.formField}
                  variant="outlined"
                  name="phone"
                  value={contact.phone}
                  label="Phone"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  className={classes.formField}
                  variant="outlined"
                  name="message"
                  value={contact.message}
                  label="Message"
                  onChange={handleChange}
                />
              </Grid>

              {error === "" ? (
                false
              ) : (
                <Grid item xs={12}>
                  <p className={classes.error}>{error}</p>
                </Grid>
              )}
              <Grid item xs={12}>
                {loading ? (
                  <Button className={classes.btnLoading} disabled>
                    <CircularProgress />{" "}
                  </Button>
                ) : (
                  <Button
                    className={classes.btnMain}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}

export default ContactButton;
