/* Import Material-ui */
import { makeStyles } from "@material-ui/core/styles";

/* Import image */

export const useStyles = makeStyles((theme) => ({
  headerSection: {
    padding: "20px 64px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid",
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    "& ul li": {
      listStyle: "none",
      display: "inline",
      margin: "0px 10px 0px 0px",
    },
    "& li": {
        cursor: "pointer",
        padding: "20px 10px",
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
    "& img": {
      height: "55px",
    },
    "@media only screen and (max-width: 800px)": {
      padding: "20px 30px",
    },
    "@media only screen and (max-width: 479px)": {
      padding: "20px 20px",
    },
  },
  drawerList: {
    width: 250,
  },
  drawerBottom: {
      position: "absolute",
    bottom: 0,
  },
}));
