/* Import General */
import "./App.css";
import { Router } from "@reach/router";

/* Import Material-ui */
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

/* Import Custom */
import Home from "./components/app/home";
import Signin from "./components/auth/signIn";
import Quotation from "./components/app/quotation";
import Expenses from "./components/app/expenses";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: "#4fb3bf",
      // main: "#7c8e90",
      light: "#e8eaf6",
      main: "#3f51b5",
      // dark: "#005662",
    },
    secondary: {
      // light: "#ffff6b",
      // main: "#fdd835",
      // main: "#7c8e90",
      main: "#f9b934",
      // dark: "#c6a700",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router basepath="/">
        <Home path="/" />
        <Signin path="/login" />
        <Quotation path="/quotation" />
        <Expenses path="/expenses" />
      </Router>
    </ThemeProvider>
  );
}

export default App;
