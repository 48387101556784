/* Import Material-ui */
import { makeStyles } from "@material-ui/core/styles";

/* Import image */
import login_bg from "../../../images/login_bg.jpg";

export const useStyles = makeStyles((theme) => ({
  section: {
    height: "100vh",
    backgroundImage: `url(${login_bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  authContainer: {
    maxWidth: "450px",
    flex: "1 1 auto",
    "& > div": {
      margin: "20px",
      padding: "20px 30px",
    }
  },
  paper: {
    color: "#000",
    "& > form > p, > form > div": {
      width: "100%",
      margin: "10px 0 20px 0",
    },
    "& button": {
      width: "100%",
      height: "40px",
      margin: "20px 0 10px 0",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  btnMain: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "700",
    height: "40px",
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  btnLoading: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "700",
    height: "40px",
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.primary.main,
    },
    "& > span > div": {
      height: "25px !important",
      width: "25px !important",
      color: theme.palette.primary.contrastText,
    }
  },
  error: {
    color: "#aa0000",
    margin: "0 !important",
    fontSize: "0.7em",
  },
}));